import { create } from "@storybook/theming";
import logo from "./logo.png";

export default create({
  base: "dark",

  colorPrimary: "#FFFFFF",
  colorSecondary: "#10AB55",

  // User Interface
  appBg: "#1C1C1E",
  appContentBg: "#1C1C1E",
  appBorderColor: "#1C1C1E",
  appBorderRadius: 4,

  // Typography
  fontBase: '"Inter", sans-serif',

  // Text colors
  textColor: "#FFFFFF",

  // Toolbar default and active colors
  barTextColor: "#FFFFFF",
  barBg: "#1d1e21",

  // Form colors
  inputBg: "#25262A",
  inputBorderRadius: "1rem",

  // Brand
  brandTitle: "caiosb | ui",
  brandUrl: "https://caiosb.com",
  brandImage: logo,
  brandTarget: "_self",
});
